import { useMemo } from 'react';

import { Carousel, useCarouselState } from 'components/Carousel';
import { StyledVersionedRouterLink } from 'components/Link/StyledVersionedRouterLink';
import { useIsClient } from 'hooks/useIsClient';
import { SearchAlgoliaRecommendedListing } from 'modules/search/algolia/types/SearchAlgoliaRecommendedListing';
import { range } from 'utils/functional/array/range';

import {
  TileCarouselHolder,
  TileCarouselSeeAllButton,
} from './TileCarousel.styled';
import { TileCarouselHeader } from './TileCarouselHeader';
import { TileCarouselNavigation } from './TileCarouselNavigation';
import { TileCarouselTile } from './TileCarouselTile';

type Props = {
  headline: string | null | undefined;
  results: SearchAlgoliaRecommendedListing[] | undefined;
  seeAll: {
    text: string;
    action:
      | { type: 'link'; url: string; state?: Record<string, unknown> }
      | { type: 'button'; onClick: () => void };
  };
  listingType: string;
  carouselLocation?: string;
  fromListingId?: string;
  layout?: 'SLIM' | 'REGULAR';
  source?: string;
  additionalAnalyticsProps?: Record<string, string>;
};

export function TileCarousel({
  headline,
  results,
  seeAll,
  listingType,
  carouselLocation,
  fromListingId,
  layout = 'REGULAR',
  source,
  additionalAnalyticsProps,
}: Props) {
  const carouselState = useCarouselState({
    slideCount: results?.length ?? 0,
    responsiveSlidesPerPage:
      layout === 'SLIM'
        ? { xs: 1, sm: 1, md: 2, lg: 3 }
        : { xs: 1, sm: 1, md: 3, lg: 4 },
  });

  const carouselHeight = layout === 'SLIM' ? 205 : 338;

  const linkNode = useMemo(() => {
    if (!seeAll) return null;

    switch (seeAll.action.type) {
      case 'link':
        return (
          <StyledVersionedRouterLink
            data-qa-id="tile-carousel-see-all"
            to={seeAll.action.url}
            state={seeAll.action.state}
          >
            {seeAll.text}
          </StyledVersionedRouterLink>
        );
      case 'button':
        return (
          <TileCarouselSeeAllButton
            data-qa-id="tile-carousel-see-all"
            onClick={seeAll.action.onClick}
          >
            {seeAll.text}
          </TileCarouselSeeAllButton>
        );
    }
  }, [seeAll]);

  const isClient = useIsClient();
  return (
    <>
      <TileCarouselHeader
        layout={layout}
        headline={headline}
        linkNode={linkNode}
      />

      <TileCarouselHolder $layout={layout}>
        <Carousel
          state={carouselState}
          height={carouselHeight}
          data-qa-id="tile-carousel"
          navigation={
            isClient && <TileCarouselNavigation carouselState={carouselState} />
          }
          slides={
            results && results.length > 0
              ? results.map((result) => (
                  <div
                    data-qa-id="tile-carousel-slide"
                    data-qa-listing-id={result.objectID}
                  >
                    <TileCarouselTile
                      result={result}
                      listingType={listingType}
                      carouselLocation={carouselLocation}
                      fromListingId={fromListingId}
                      layout={layout}
                      source={source}
                      additionalAnalyticsProps={additionalAnalyticsProps}
                    />
                  </div>
                ))
              : range(4).map((index) => (
                  <div key={`section-${index}`}>
                    <div />
                  </div>
                ))
          }
        />
      </TileCarouselHolder>
    </>
  );
}
