import { Box } from 'components/Box';
import { FilestackImage } from 'components/FilestackImage/FilestackImage';
import { Initials } from 'components/Initials';
import { BodyMicro } from 'components/Text/BodyMicro';
import { H4 } from 'components/Text/H4';
import { H5 } from 'components/Text/H5';
import { SearchAlgoliaRecommendedListing } from 'modules/search/algolia/types/SearchAlgoliaRecommendedListing';
import { colors } from 'theme/theme';
import { trackClicked } from 'utils/analytics/track/trackClicked';

import { SectionSearchTile } from './SectionSearchTile';
import { TruncatedText } from './TruncatedText';

type SectionProps = {
  result: SearchAlgoliaRecommendedListing;
  listingType: string;
  carouselLocation?: string;
  fromListingId?: string;
  layout: 'SLIM' | 'REGULAR';
  source?: string;
  additionalAnalyticsProps?: Record<string, string>;
};

export function TileCarouselTile({
  result,
  listingType,
  carouselLocation,
  fromListingId,
  layout,
  source,
  additionalAnalyticsProps,
}: SectionProps) {
  let location = result.city || '';

  if (result.city && result.state) {
    location += ', ';
  }

  if (result.state) {
    location += result.state;
  }

  return (
    <Box m="4px auto" px="4px">
      <SectionSearchTile
        layout={layout}
        href={
          typeof result.url === 'string'
            ? result.url
            : result.url?.[CURRENT_LOCALE] || ''
        }
        onClick={() => {
          trackClicked('Featured Listing', {
            ...(additionalAnalyticsProps || {}),
            listing_type: listingType,
            from_listing_id: fromListingId || null,
            to_listing_id: result.objectID,
            carousel_location: carouselLocation || null,
            layout,
            source,
          });
        }}
      >
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-evenly"
          flexDirection="column"
          width="100%"
          height="100%"
          textAlign="left"
          mb="10px"
        >
          {layout === 'REGULAR' && (
            <Box
              height={120}
              display="flex"
              alignItems="center"
              mb={20}
              width="100%"
              justifyContent="center"
            >
              {result.logo ? (
                <FilestackImage
                  width={240}
                  height={120}
                  expandSize
                  alt={result.name}
                  data={result.logo}
                  noCrop
                />
              ) : (
                <Initials name={result.name} size={120} />
              )}
            </Box>
          )}

          <Box width="100%">
            <H5
              width="100%"
              color={colors.lightContentGrey}
              lineHeight={1}
              $textOverflow="ellipsis"
              $whiteSpace="nowrap"
              overflow="hidden"
              display="block"
              my={0}
            >
              {result.orgName}
            </H5>

            <H4 my="15px" lineHeight={1.375}>
              <TruncatedText>{result.name}</TruncatedText>
            </H4>
          </Box>

          <BodyMicro my={0} lineHeight={1} color={colors.lightContentGrey}>
            {location}
          </BodyMicro>
        </Box>
      </SectionSearchTile>
    </Box>
  );
}
